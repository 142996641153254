import React from "react";

const SectionAnchor = ({ id, top }) => (
  <div
    id={id}
    style={{ position: "absolute", marginBottom: "100px", top: top, left: 0 }}
  ></div>
);

export default SectionAnchor;
