import React, { useEffect, useState } from "react";
import { Flex, Button, Text, Link } from "@chakra-ui/core";

const CookieBanner = ({ data }) => {
  const CONSENT_COOKIE_NAME = "cookiesAccepted";

  const [bannerClicked, setBannerClicked] = useState(true);
  useEffect(() => {
    setBannerClicked(localStorage.getItem(CONSENT_COOKIE_NAME) !== null);
  }, []);

  const {
    text: { start, privacyPolicy, middle, cookiesPolicy, end },
    acceptText,
    privacyPolicyURL,
    cookiesPolicyURL,
  } = data;

  const acceptCookies = () => {
    localStorage.setItem(CONSENT_COOKIE_NAME, "true");
    setBannerClicked(true);
  };

  if (bannerClicked) return null;

  return (
    <Flex
      position="fixed"
      bottom="0px"
      w="100%"
      zIndex={100}
      justifyContent="space-between"
      alignItems="center"
      bg="#000"
      padding={4}
      flexDirection={{ base: "column", sm: "row" }}
    >
      <Text color="#fff">
        {start}
        <Link textDecor="underline" target="_blank" href={privacyPolicyURL}>
          {privacyPolicy}
        </Link>
        {middle}
        <Link textDecor="underline" target="_blank" href={cookiesPolicyURL}>
          {cookiesPolicy}
        </Link>
        {end}
      </Text>
      <Button
        mt={{ base: "1rem", sm: 0 }}
        ml={{ base: 0, sm: "1rem" }}
        onClick={() => acceptCookies()}
      >
        {acceptText}
      </Button>
    </Flex>
  );
};

export default CookieBanner;
