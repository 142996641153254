import React, { useState } from "react";
import { useStaticQuery } from "gatsby";
import { graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import SEO from "../components/seo";
import Header from "../components/header";
import Slide from "../components/slide";
import CookieBanner from "../components/cookieBanner";
import {
  Box,
  Heading,
  Flex,
  Button,
  Stack,
  Text,
  Grid,
  Image,
  Link,
} from "@chakra-ui/core";
import content from "../content.json";
import NewsTile from "../components/newsTile";
import SectionAnchor from "../components/sectionAnchor";
import "../components/layout.css";
const SlideCarousel = React.lazy(() => import("../components/slideCarousel"));

const DATA = graphql`
  query {
    fullbg: file(relativePath: { eq: "fullbackground.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    master {
      newses(stage: PUBLISHED, orderBy: createdAt_DESC) {
        id
        createdAt
        image {
          handle
          width
          height
        }
        titlePl
        titleEn
        descriptionPl
        descriptionEn
        link
      }
    }
  }
`;

const IndexPage = () => {
  const { fullbg, master } = useStaticQuery(DATA);
  const fullbgData = fullbg.childImageSharp.fluid;
  const [lang, setLang] = useState("pl");
  const locale = lang === "pl" ? content.pl : content.en;
  const isSSR = typeof window === "undefined";
  return (
    <>
      {!isSSR && <CookieBanner data={locale.cookies} />}
      <SEO title="Seedstone Incubation Fund" />
      <BackgroundImage fluid={fullbgData} backgroundColor={`#000`}>
        <Header lang={lang} setLang={setLang} locale={locale} />
        <Box
          color="white"
          background="linear-gradient(0deg,rgba(0, 0, 0, 1) 10%,rgba(0, 212, 255, 0) 100%)"
        >
          <Flex
            justify="center"
            align="center"
            h={["auto", "auto", "calc(100vh - 200px)"]}
            minHeight="400px"
            direction="column"
            p="0 32px"
            overflowX="hidden"
          >
            {/* <Heading fontSize="3xl" fontWeight="400" w={800} textAlign="center">
              Lorem Ipsum
            </Heading> */}
            <Heading
              fontSize={["xl", "2xl", "4xl", "6xl"]}
              fontWeight="400"
              w={[300, 500, 600, 800]}
              textAlign="center"
              dangerouslySetInnerHTML={{ __html: locale.landing.title }}
            ></Heading>
            <a href="#submit" style={{ marginTop: "1.5rem" }}>
              <Button
                size="lg"
                variant="outline"
                // mt={6}
                _hover={{ color: "orange.500", bg: "white" }}
              >
                {locale.landing.button}
              </Button>
            </a>
          </Flex>
          <Flex
            m="0 auto"
            p="0 32px"
            maxW="1200px"
            justify="space-between"
            direction={["column", "column", "row", "row"]}
            position="relative"
          >
            <SectionAnchor id="about" top={-50} />
            <Stack
              flex="2"
              justify="center"
              mb={"32px"}
              textAlign={["center", "center", "left"]}
            >
              {/* <Heading size="md">Lorem Ipsum</Heading> */}
              <Heading fontSize={["xl", "2xl", "4xl", "6xl"]}>
                {locale.aboutFund.title}
              </Heading>
            </Stack>
            <Stack
              flex="3"
              p="32px"
              spacing={6}
              border="1px solid rgba(255,255,255,0.17)"
              borderRadius="10px"
              lineHeight="1.8rem"
            >
              <Text>{locale.aboutFund.paragraph1}</Text>
              <Text>{locale.aboutFund.paragraph2}</Text>
              <Text>{locale.aboutFund.paragraph3}</Text>
            </Stack>
          </Flex>
        </Box>
      </BackgroundImage>
      <Box
        py={16}
        // h="1600px"
        background="url(/background_lines.svg) no-repeat center center"
        backgroundColor="black"
        color="white"
      >
        <Grid
          display={["none", "none", "grid"]}
          templateColumns="2fr 1fr 1fr 1fr 1fr 1fr"
          templateRows="1fr 2fr 2fr 2fr"
          gridColumnGap="2px"
          maxWidth="1200px"
          margin="80px auto"
          p="0 32px"
        >
          <Box borderBottom="1px dashed white" />
          <Text textAlign="center" borderBottom="1px dashed white">
            {locale.table.columnHeader1}
          </Text>
          <Text textAlign="center" borderBottom="1px dashed white">
            {locale.table.columnHeader2}
          </Text>
          <Text textAlign="center" borderBottom="1px dashed white">
            {locale.table.columnHeader3}
          </Text>
          <Text textAlign="center" borderBottom="1px dashed white">
            {locale.table.columnHeader4}
          </Text>
          <Text textAlign="center" borderBottom="1px dashed white">
            {locale.table.columnHeader5}
          </Text>
          <Flex height="100px" borderBottom="1px dashed white">
            <Text as="p" m="auto">
              {locale.table.rowHeader1}
            </Text>
          </Flex>
          <Flex justify="center" align="center" borderBottom="1px dashed white">
            <Image src="/checkmark.svg" />
          </Flex>
          <Flex justify="center" align="center" borderBottom="1px dashed white">
            <Image src="/checkmark.svg" />
          </Flex>
          <Flex justify="center" align="center" borderBottom="1px dashed white">
            <Image src="/checkmark.svg" />
          </Flex>
          <Flex justify="center" align="center" borderBottom="1px dashed white">
            <Image src="/checkmark.svg" />
          </Flex>
          <Flex justify="center" align="center" borderBottom="1px dashed white">
            <Image src="/checkmark.svg" />
          </Flex>
          <Flex height="100px" borderBottom="1px dashed white">
            <Text as="p" m="auto">
              {locale.table.rowHeader2}
            </Text>
          </Flex>
          <Flex justify="center" align="center" borderBottom="1px dashed white">
            <Image src="/checkmark.svg" />
          </Flex>
          <Flex justify="center" align="center" borderBottom="1px dashed white">
            <Image src="/checkmark.svg" />
          </Flex>
          <Flex justify="center" align="center" borderBottom="1px dashed white">
            <Image src="/checkmark.svg" />
          </Flex>
          <Flex justify="center" align="center" borderBottom="1px dashed white">
            <Image src="/checkmark.svg" />
          </Flex>
          <Flex justify="center" align="center" borderBottom="1px dashed white">
            <Image src="/checkmark.svg" />
          </Flex>
          <Flex height="100px">
            <Text as="p" m="auto">
              {locale.table.rowHeader3}
            </Text>
          </Flex>
          <Flex justify="center" align="center">
            <Image src="/checkmark.svg" />
          </Flex>
          <Flex justify="center" align="center">
            <Image src="/checkmark.svg" />
          </Flex>
          <Flex justify="center" align="center">
            <Image src="/checkmark.svg" />
          </Flex>
          <Flex justify="center" align="center">
            <Image src="/checkmark.svg" />
          </Flex>
          <Flex justify="center" align="center">
            <Image src="/checkmark.svg" />
          </Flex>
        </Grid>
        <Flex position="relative" justify="flex-start">
          <Heading
            margin="0 auto"
            width="1200px"
            p="0 32px"
            position="relative"
            zIndex="10"
            fontSize={["xl", "2xl", "3xl", "3xl"]}
          >
            {locale.fundPolicy.title}
          </Heading>
          <Box
            ml="90px"
            backgroundColor="#1E51E8"
            height="10px"
            flex="1"
            position="absolute"
            right="0"
            top="50%"
            width={[
              "calc((100vw - 1200px)/2 + 500px)",
              "calc((100vw - 1200px)/2 + 550px)",
              "calc((100vw - 1200px)/2 + 600px)",
            ]}
            maxWidth="60vw"
          />
        </Flex>
        <Grid
          templateColumns={["1fr", "1fr", "1fr 1fr 1fr"]}
          templateRows={["1fr 1fr 1fr", "1fr 1fr 1fr", "1fr"]}
          columnGap={24}
          rowGap={0}
          maxWidth="1200px"
          margin="80px auto"
          p="0 32px"
        >
          <Stack>
            <Heading fontSize={["xl", "2xl", "3xl", "3xl"]}>
              {locale.fundPolicy.subheading1}
            </Heading>
            <Text>{locale.fundPolicy.text1}</Text>
          </Stack>
          <Stack>
            <Heading fontSize={["xl", "2xl", "3xl", "3xl"]}>
              {locale.fundPolicy.subheading2}
            </Heading>
            <Text>{locale.fundPolicy.text2}</Text>
          </Stack>
          <Stack>
            <Heading fontSize={["xl", "2xl", "3xl", "3xl"]}>
              {locale.fundPolicy.subheading3}
            </Heading>
            <Text>{locale.fundPolicy.text3}</Text>
            <Text opacity="0.5">{locale.fundPolicy.subText}</Text>
          </Stack>
        </Grid>
      </Box>
      <Box position="relative" overflow="hidden">
        <SectionAnchor id="news" top={0} />
        <Stack
          align="center"
          justify="center"
          direction="column"
          spacing={2}
          py={16}
        >
          <Heading size="md" color="#414141">
            {locale.news.subtitle}
          </Heading>
          <Heading mt={36}>{locale.news.title}</Heading>
          <Flex
            mt={["32px", "40px", "40px", "72px"]}
            direction={["column", "column", "column", "row"]}
            maxWidth={"1316px"}
            mx={[0, 0, 0, "-28px"]}
            px={"10px"}
          >
            {master.newses.slice(0, 3).map((news, index) => (
              <NewsTile
                key={index}
                image={news.image}
                date={new Date(news.createdAt).toLocaleDateString("pl-PL", {
                  timeZone: "Europe/Warsaw",
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })}
                title={lang === "pl" ? news.titlePl : news.titleEn}
                content={
                  lang === "pl" ? news.descriptionPl : news.descriptionEn
                }
                link={news.link}
              />
            ))}
          </Flex>
        </Stack>
      </Box>
      <Box>
        <Box
          // pt={16}
          // h="1600px"
          background="url(/background_lines.svg) no-repeat center center"
          backgroundColor="black"
          color="white"
          position="relative"
        >
          <SectionAnchor id="submit" top={0} />
          <Stack
            align="center"
            justify="center"
            direction="column"
            spacing={2}
            py={16}
          >
            <Heading size="md">{locale.submitProject.subtitle}</Heading>
            <Heading size="2xl">{locale.submitProject.title}</Heading>
            <Stack
              maxW="1200px"
              margin="48px auto"
              px="32px"
              fontSize="xl"
              spacing={8}
            >
              <Text>{locale.submitProject.paragraph1}</Text>
              <Text>{locale.submitProject.paragraph2}</Text>
              <Stack pt={6} alignSelf="flex-start" borderTop="1px solid white">
                {/* <Stack isInline spacing={4}>
                  <Text>{locale.submitProject.proposal}</Text>
                  <Image src="/download_icon.svg" />
                </Stack> */}
                <Link href="/formularz.docx" isExternal>
                  <Stack isInline spacing={4}>
                    <Text>{locale.submitProject.form}</Text>
                    <Image src="/download_icon.svg" />
                  </Stack>
                </Link>
                {/* <Stack isInline spacing={4}>
                  <Text>{locale.submitProject.template}</Text>
                  <Image src="/download_icon.svg" />
                </Stack> */}
              </Stack>
            </Stack>
          </Stack>
        </Box>
        <Flex
          backgroundColor="#1e51e8"
          direction="column"
          color="white"
          py="80px"
          position="relative"
        >
          <SectionAnchor id="team" top={0} />
          <Text textAlign="center" mb="26px" fontSize="20px">
            {locale.team.subtitle}
          </Text>
          <Text textAlign="center" mb="48px" fontSize="50px">
            {locale.team.title}
          </Text>
          {!isSSR && (
            <React.Suspense fallback={<div />}>
              <SlideCarousel>
                <Slide
                  image={"/marcin-fejfer-partner-funduszu.png"}
                  name={locale.team.name1}
                  title={locale.team.position1}
                  description={locale.team.desc1}
                />
                <Slide
                  image={"/kuda-dudek-partner-funduszu.png"}
                  name={locale.team.name3}
                  title={locale.team.position3}
                  description={locale.team.desc3}
                />
                <Slide
                  image={"/jakub-szumielewicz-partner-funduszu.png"}
                  name={locale.team.name2}
                  title={locale.team.position2}
                  description={locale.team.desc2}
                />
                <Flex
                  maxWidth="325px"
                  justify="center"
                  align="center"
                  direction="column"
                  m="0 auto"
                >
                  <Flex mb="48px" justify="center">
                    <Image
                      width="157px"
                      height="157px"
                      borderRadius="50%"
                      src={"/jagoda.jpg"}
                    />
                  </Flex>
                  <Box mb="12px">
                    <Text fontSize="2xl" textAlign="center">
                      {locale.team.name4}
                    </Text>
                  </Box>
                  <Box mb="24px" textAlign="center">
                    <Text fontSize="22px">{locale.team.position4}</Text>
                  </Box>
                  <Box>
                    <Text textAlign="justify">{locale.team.desc4}</Text>
                  </Box>
                </Flex>
                {/* <Flex
                  maxWidth="325px"
                  justify="center"
                  align="center"
                  direction="column"
                  m="0 auto"
                >
                  <Flex mb="48px" justify="center">
                    <Image
                      width="157px"
                      height="157px"
                      borderRadius="50%"
                      src={"/adam.jpg"}
                      objectFit="cover"
                    />
                  </Flex>
                  <Box mb="12px">
                    <Text fontSize="2xl" textAlign="center">
                      {locale.team.name5}
                    </Text>
                  </Box>
                  <Box mb="24px" textAlign="center">
                    <Text fontSize="22px">{locale.team.position5}</Text>
                  </Box>
                  <Box>
                    <Text textAlign="justify">{locale.team.desc5}</Text>
                  </Box>
                </Flex> */}
              </SlideCarousel>
            </React.Suspense>
          )}
        </Flex>
      </Box>
      <Flex direction="column">
        <Flex backgroundColor="black" color="white" py="72px" justify="center">
          <Flex
            justify={["center", "center", "space-between"]}
            align="center"
            maxWidth="1100px"
            width="100%"
            mx="32px"
          >
            <Stack>
              <Image src="/logo.svg" />
            </Stack>
            <Stack
              isInline
              spacing={10}
              display={["none", "none", "inline-block"]}
            >
              <Link href="#about">{locale.navbar.menu1}</Link>
              <Link href="#news">{locale.navbar.menu2}</Link>
              <Link href="#team">{locale.navbar.menu3}</Link>
            </Stack>
          </Flex>
        </Flex>
        <Flex
          as="footer"
          p="64px 32px"
          maxW="1200px"
          margin="0 auto"
          direction={["column", "column", "column", "row"]}
          justify="space-between"
        >
          <Stack flex={["1", "1", "1", "2"]}>
            <Text fontSize="xs">Informacje dla akcjonariuszy</Text>
            <Text fontSize="xs">{locale.footer.ir}</Text>
          </Stack>
          <Stack
            flex={["1", "1", "1", "3"]}
            flexDirection={["column", "column", "row", "row"]}
            justify={["center", "center", "center", "flex-end"]}
          >
            <Image height="64px" objectFit="contain" src="/footer/fe.jpg" />
            <Image height="64px" objectFit="contain" src="/footer/rp.jpg" />
            <Image height="64px" objectFit="contain" src="/footer/ue.jpg" />
          </Stack>
        </Flex>
      </Flex>
    </>
  );
};

export default IndexPage;
