import React from "react";
import { Box, Flex, Text } from "@chakra-ui/core";
import GraphImg from "graphcms-image";

const NewsTile = ({ image, date, title, content, link }) => {
  return (
    <Box h="auto">
      {link ? (
        <a href={link} style={{ height: "100%" }}>
          <Flex
            direction="column"
            borderRadius="19px"
            maxWidth="375px"
            backgroundColor="#f6f6f6"
            margin={[0, 0, 0, "0 28px"]}
            overflow="hidden"
            mt={["32px", "32px", "32px", 0]}
            h="100%"
          >
            <GraphImg image={image} style={{ height: "200px" }} fit="crop" />
            <Box direction="column" padding="38px">
              <Text mb="8px">{date}</Text>
              <Text mb="24px" fontSize="22px" fontWeight="500">
                {title}
              </Text>
              <Text textAlign="justify">{content}</Text>
            </Box>
          </Flex>
        </a>
      ) : (
        <Flex
          direction="column"
          borderRadius="19px"
          maxWidth="375px"
          backgroundColor="#f6f6f6"
          margin={[0, 0, 0, "0 28px"]}
          overflow="hidden"
          mt={["32px", "32px", "32px", 0]}
          h="100%"
        >
          <GraphImg image={image} style={{ height: "200px" }} fit="crop" />
          <Box direction="column" padding="38px">
            <Text mb="8px">{date}</Text>
            <Text mb="24px" fontSize="22px" fontWeight="500">
              {title}
            </Text>
            <Text textAlign="justify">{content}</Text>
          </Box>
        </Flex>
      )}
    </Box>
  );
};

export default NewsTile;
