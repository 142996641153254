import React from "react";
import { Box, Image, Flex, Text } from "@chakra-ui/core";

const Slide = ({ image, name, title, description }) => (
  <Flex
    maxWidth="325px"
    justify="center"
    align="center"
    direction="column"
    m="0 auto"
  >
    <Box mb="48px">
      <Image src={image} />
    </Box>
    <Flex mb="12px" justify="center">
      <Text fontSize="2xl" textAlign="center">
        {name}
      </Text>
    </Flex>
    <Flex mb="24px" justify="center">
      <Text fontSize="22px" textAlign="center">
        {title}
      </Text>
    </Flex>
    <Box>
      <Text textAlign="justify">{description}</Text>
    </Box>
  </Flex>
);

export default Slide;
