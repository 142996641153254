import React from "react";
import { Box, Heading, Image, Flex, Stack, Link } from "@chakra-ui/core";

const Header = ({ lang, setLang, locale }) => (
  <Box
    as="header"
    background="transparent"
    // marginBottom="1.45rem"
    color="white"
  >
    <Flex
      m="0 auto"
      maxW="960px"
      p="1.45rem 1.0875rem"
      direction={["column", "column", "row"]}
      justify={["center", "center", "space-between"]}
      align="center"
    >
      <Heading m={0}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          <Image src="/logo.svg" />
        </Link>
      </Heading>
      <Flex>
        <Stack isInline spacing={10} display={["none", "none", "inline-block"]}>
          <Link href="#about">{locale.navbar.menu1}</Link>
          <Link href="#news">{locale.navbar.menu2}</Link>
          <Link href="#team">{locale.navbar.menu3}</Link>
          <Link href="#submit" textDecoration="underline">
            {locale.navbar.menu4}
          </Link>
        </Stack>
        <Box ml={[0, 0, "2.5rem"]} mt={["1rem", "1rem", 0]}>
          <button
            onClick={lang === "pl" ? () => setLang("en") : () => setLang("pl")}
            style={{ cursor: "pointer" }}
          >
            <Image
              src={lang === "pl" ? "/flag-uk.png" : "/flag-poland.png"}
              width="30px"
            />
          </button>
        </Box>
      </Flex>
    </Flex>
  </Box>
);

export default Header;
